<template>
  <div
    :class="hitsize"
    :style="{ ...adaptStyle, maxWidth: maxpx + 'px', fontSize: fontSize }"
  >
    <span
      v-html="text"
    ></span>
    <div
      class="virtual" 
      :class="randomClass"
    >
      <template v-for="(item, index) in maxNumber">
        <span
          v-if="item >= source[0]"
          :key="index"
          :ref="`f${item}`"
          :class="`f${item}`"
          :style="{ fontSize: `${item}px` }"
          v-html="text"
        >
        </span>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdaptText',
  props: {
    text: {
      default: '',
      type: String,
    },
    maxpx: {
      default: 1,
      type: Number,
    },
    source: {
      type: Array,
      default: [12, 16],
    },
    adaptStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hitsize: '',
      fontSize: '',
      randomClass: 'virtual-' + Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    maxNumber() {
      return Object.keys(Array.from(Array(this.source[1] + 1))) || []
    },
  },
  watch: {
    text() {
      this.$nextTick(() => this.calsize())
    },
  },
  mounted() {
    this.calsize()
  },
  methods: {
    calsize() {
      const keys = Object.keys(this.$refs).reverse()
      this.hitsize = `f${this.source[0]} oversize`
      this.fontSize = `${+keys[0].replace('f', '')}px`
      this.$nextTick(() => {
        for (var i = 0; i < keys.length; i++) {
          const el = this.$refs?.[keys[i]]?.[0]
          if (el.offsetWidth < this.maxpx) {
            this.hitsize = keys[i]
            this.fontSize = `${+keys[i].replace('f', '')}px`
            this.hiddenVirtualStyle()
            break
          }
          this.fontSize = `${+keys[i].replace('f', '')}px`
        }
        this.hiddenVirtualStyle()
      })
    },
    hiddenVirtualStyle() {
      document.querySelector(`.${this.randomClass}`)?.remove?.()
    },
  },
}
</script>
<style lang="less" scoped>
.virtual {
  visibility: hidden;
  position: absolute;
  width: 10000px;
}
.oversize {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
