<template>
  <s-dialog
    v-model:visible="nonRefundableVisible"
    class="non-refundable-dialog"
    :close-on-click-modal="false"
    show-close
    append-to-body
  >
    <template #title>
      {{ language.SHEIN_KEY_PC_25727 }}
    </template>
    <template
      v-for="item in nonRefundableList"
      :key="item.type"
    >
      <div class="non-refundable-dialog__tips">
        <Icon
          name="sui_icon_information_supplement_14px"
          size="16px"
          color="#959595"
        />
        <span class="non-refundable-dialog__tips-text">{{ item.tips }}</span>
      </div>
      <div class="non-refundable-dialog__products">
        <div
          v-for="(product, index) in item.products"
          :key="`${product.sku_code}-${index}`"
          class="non-refundable-dialog__product"
        >
          <CommonImage
            class="non-refundable-dialog__product-img"
            :item="product"
            :cut-img-width="100"
          >
            <template #others>
              <!-- 商品数量 -->
              <div
                class="non-refundable-dialog__product-quantity"
                :style="`color: ${ +product.quantity >= 2 ? '#FA6338' : ''}`"
              >
                x{{ product.quantity }}
              </div>
            </template>
          </CommonImage>
          <div class="non-refundable-dialog__price">
            <template v-if="!isSuggested">
              <!-- 促销icon -->
              <cartListItemIcon
                :item="product"
                :is-premium-member="isPremiumMember(product)"
                :show-club-gift-on-checkout="isSheinClubGiftItem(product)"
                :top="2"
                :prime-icon-v2="discountLogoByAbt"
              />
              <em
                v-if="isVipItem(product)"
                :class="[GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag']"
              ></em>
            </template>
            <span
              class="non-refundable-dialog__price-value"
              :style="`color: ${priceColor(product)}`"
            >
              {{ getPriceByAbt(product) }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </s-dialog>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import CommonImage from 'public/src/pages/checkout/components/CommonImage.vue'
import cartListItemIcon from 'public/src/pages/cart_new/components/list/cartListItemIcon.vue'
import { isProductRefundable } from 'public/src/pages/checkout/utils.js'
import { NonRefundableType, nonRefundablePriority } from 'public/src/pages/checkout/vue_tpls/shopping_bag/config.js'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  cartModalList: {
    type: Array,
    default: () => []
  },
})

const emits = defineEmits(['update:visible'])

const store = useStore()
const checkout = computed(() => store.state.checkout)
const language = computed(() => store.state.language)
const locals = computed(() => store.state.locals)
const SiteUID = computed(() => store.getters.SiteUID)
const isSuggested = computed(() => store.getters.isSuggested)
const discountLogoByAbt = computed(() => store.getters.discountLogoByAbt)
const showArrivalPriceByAbt = computed(() => store.getters.showArrivalPriceByAbt)
const GB_cssRight = computed(() => locals.value?.GB_cssRight ?? gbCommonInfo?.GB_cssRight)

const nonRefundableVisible = computed({
  get () {
    return props.visible
  },
  set (val) {
    emits('update:visible', val)
  }
})

const nonRefundableConfig = {
  [NonRefundableType.Customized]: {
    tips: language.value.SHEIN_KEY_PC_32381, 
    products: []
  },
  [NonRefundableType.Category]: {
    tips: language.value.SHEIN_KEY_PC_32379,
    products: []
  },
  [NonRefundableType.Promotion]: {
    tips: language.value.SHEIN_KEY_PC_32380,
    products: []
  },
  [NonRefundableType.LowGrossMargin]: {
    tips: language.value.SHEIN_KEY_PC_32380,
    products: []
  },
  [NonRefundableType.Withdrawal]: {
    tips: language.value.SHEIN_KEY_PC_32383,
    products: []
  },
  [NonRefundableType.RefundableWithdrawal]: {
    tips: language.value.SHEIN_KEY_PC_32382,
    products: []
  }
}

const nonRefundableList = computed(() => {
  const isDe = !!~SiteUID.value.indexOf('de')
  const priority = isDe ? nonRefundablePriority.de : nonRefundablePriority.default
  props.cartModalList.forEach(item => {
    const { nonRefundable, nonRefundableInfo }  = isProductRefundable(SiteUID.value, item)
    // 可退换的商品不展示
    if (!nonRefundable) return
    let type = priority.find(type => nonRefundableInfo[type])
    // 特殊逻辑：非 de 市场，促销不可退及低毛利不可退可视为同一分类，合并展示
    if (
      [
        NonRefundableType.Promotion, 
        NonRefundableType.LowGrossMargin
      ].includes(type)
    ) type = NonRefundableType.Promotion
    nonRefundableConfig[type].products.push(item)
  })
  return priority
    .map(type => ({
      type,
      ...nonRefundableConfig[type]
    }))
    .filter(item => !!item.products.length)
})

const isPremiumMember = (product) => {
  return product.promotionTypeIds?.includes(29) ||
    product.discount_product_mark
}

const isSheinClubGiftItem = (product) => {
  let isSheinClubGift = false
  product.product?.product_promotion_info?.forEach(promotion => {
    // 根据promotion ID查促销接口获得promotion_type=4且promotion_logo_type如果为8就是会员赠品商品）
    // 且promotion_product_type === 1就是赠品
    if (promotion.type_id == 4 && promotion.promotion_logo_type === 8 && promotion.promotion_product_type == '1') {
      isSheinClubGift = true
    }
  })
  return isSheinClubGift
}

const isVipItem = (product) => {
  return product.promotionTypeIds?.includes(12)
}

const isDiscount = (product) => {
  let result = false
  if (product.product && product.product.retailPrice) {
    if (
      product.product.salePrice &&
      product.product.retailPrice.amount !=
        product.product.salePrice.amount
    ) {
      result = true
    } else if (
      product.unitPrice &&
      product.unitPrice.amount != product.product.retailPrice.amount
    ) {
      result = true
    }
  }
  return result
}

const priceColor = (product) => {
  if(!!isSuggested.value) return ''
  // 优先展示付费会员色
  if(isPremiumMember(product)) {
    if (discountLogoByAbt.value) return '#873C00'
    return '#C96E3F'
  } 
  if (isSheinClubGiftItem(product)) return '#873C00'
  if (isVipItem(product)) return '#A78A45'
  if (isDiscount(product)) return '#FA6338'
}

const getPriceByAbt = (product) =>{
  let originPrice = product?.unitPrice?.amountWithSymbol
  if(!showArrivalPriceByAbt.value) return originPrice
  
  let actualPriceObj = checkout.value?.mall_caculate_info?.cart_sub_infos?.find(item => item.cart_id == product.id) || {}
  const { single_sub_total } = actualPriceObj
  if(!single_sub_total) return originPrice
  return single_sub_total.amountWithSymbol
}
</script>

<style lang="less" scoped>
.non-refundable-dialog {
  /deep/ .sui-dialog__body {
    max-height: 514px;
    overflow-y: scroll;
  }

  &__tips {
    padding: 8px;
    border-radius: 2px;
    border: 0.5px solid rgba(45, 104, 168, 0.20);
    background: #EBF4FF;
    display: flex;
    align-items: center;
  }

  &__tips-text {
    color: #161616;
    font-size: 12px;
    margin-left: 10px;
    text-align: left;
    line-height: 1.2;
  }

  &__products {
    display: flex;
    flex-wrap: wrap;
    margin: 12px 0 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__product {
    margin: 0 5px 8px 0;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  &__product-img {
    position: relative;
    width: 100px;
    height: 100px;
  }

  &__product-quantity {
    position: absolute;
    width: 100%;
    height: 20px;
    line-height: 20px;
    background-color: rgba(0, 0, 0, 0.50);
    bottom: -1px;
    text-align: center;
    font-size: 12px;
    color: @sui_color_white;
    font-weight: bold;
    z-index: @zindex-hack;
  }

  &__price {
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 14px;
    max-width: 100%;
    margin-top: 4px;

    .vip-exclusive-tag, .vip-exclusive-tag__ar {
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 34px 10px;
      width: 34px;
      height: 10px;
    }
    .vip-exclusive-tag {
      background-image: url(/she_dist/images/sheinvip2/s3_exclusive-3286f50076.png);
    }
    .vip-exclusive-tag__ar {
      background-image: url(/she_dist/images/sheinvip2/s3_exclusive_ar-7c299f990d.png);
      vertical-align: -1px;
    }
  }

  &__price-value {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: normal;

    font-weight: bold;
    color: #222;
    .padding-r(2px);
  }
}
</style>
