<template>
  <div>
    <div 
      class="shipping-method-item__large-wrap"
      @click.stop="handleTipsClick"
    >
      <GoodsInfo 
        v-if="lagreShipInfo.largeShipGoods && lagreShipInfo.largeShipGoods.length"
        class="shipping-method-item__large-wrap-goods"
        :large-ship-goods="lagreShipInfo.largeShipGoods"
      />
      <div class="shipping-method-item__large-wrap-tips">
        <div 
          class="detail" 
          v-html="largeShipDetail"
        >
        </div>
        <sui_icon_more_right_18px_1
          class="carts-more__icon"
          size="14px"
          color="#C44A01"
        />
      </div>
    </div>

    <ClientOnly>
      <LargeInformDialog 
        v-if="isClient"
        ref="largeInformDialogRef" 
        :lagre-ship-info="lagreShipInfo"
        :large-ship-tips="largeShipTips"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, defineAsyncComponent, onMounted } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import { sui_icon_more_right_18px_1 } from '@shein-aidc/icon-vue3'
import GoodsInfo from './GoodsInfo'
const LargeInformDialog = defineAsyncComponent(() => import('./LargeInformDialog'))

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const props = defineProps({
  lagreShipInfo: {
    type: Object,
    default: () => {}
  },
})

const isClient = ref(false)

const store = useStore()
const language = computed(() => store.state.language)

const largeShipTips = computed(() => {
  // 当前有大件商品且时效文案不为空时展示
  if(!!props.lagreShipInfo.largeShipTimeDesc && !!props.lagreShipInfo.largeShipGoods?.length) {
    const num = props.lagreShipInfo.largeShipGoods.reduce((prev, next) => prev += +next?.quantity, 0)
    return template(num, props.lagreShipInfo.largeShipTitle, language.value.SHEIN_KEY_PC_31616)
  }

  return ''
})

const largeShipDetail = computed(() => {
  return props.lagreShipInfo.largeShipTimeDesc + largeShipTips.value
})

const largeInformDialogRef = ref(null)

const handleTipsClick = () => {
  daEventCenter.triggerNotice({
    daId: '1-11-1-231',
  })
  largeInformDialogRef.value?.openDialog()
}

onMounted(() => {
  isClient.value = true
})
</script>

<style lang="less" scoped>
.shipping-method-item__large-wrap {
  border-radius: 2px;
  border: 0.5px solid #FFE2D3;
  background: #FFF6F3;

  display: flex;
  padding: 6px 8px;
  align-items: center;

  .shipping-method-item__large-wrap-goods {
    margin-right: 8px;
  }

  .shipping-method-item__large-wrap-tips {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: @sui_color_highlight;
    font-size: 12px;

    .detail {
      overflow : hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
