<template>
  <div class="shopping-bag-tips">
    <div
      v-if="sfsProductsTipsVisible" 
      class="shopping-bag-tips__sfs"
      :style="{
        'cursor': isOnlyOneSfs ? 'default' : 'pointer'
      }"
      @click.stop="openSfsProductsDrawer"
    >
      <span class="shopping-bag-tips__sfs-label">
        {{ language.SHEIN_KEY_PC_32654 }}
      </span>
      <span class="shopping-bag-tips__sfs-text">
        {{ sfsProductsTips }}
      </span>
    </div>
    <s-alert
      v-if="showTaxHint"
      v-expose="{
        id: '1-11-1-165',
        data: { mall_code: mallCode, location: 'page' }
      }"
      v-tap="{
        id: '1-11-1-166',
        data: { mall_code: mallCode, location: 'page' }
      }"
      class="shopping-bag-tips__hint"
      type="info" 
      :max-rows="1000"
      :jumpable="!isLastGoods"
      @click.stop="clickTaxHint"
    >
      <div v-html="taxHint"></div>
    </s-alert>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useMapState, useMapGetters, useMapMutation as useMapMutations } from 'public/src/pages/store_pages/store/mapHook'

const props = defineProps({
  cartsInfo: {
    type: Array,
    default: () => []
  },
  mallCode: {
    type: [String, Number],
    default: '',
  },
  isLastGoods: {
    type: Boolean,
    default: false
  },
  showShoppingBagItemDetail: {
    type: Boolean,
    default: false
  }
})

const {
  language, 
  checkout, 
  touchRuleInfo 
} = useMapState([
  'language',
  'checkout', 
  'touchRuleInfo'
])
const { 
  SiteUID, 
  isShowbrTaxhint,
  CartOrderSFSVisual
} = useMapGetters([
  'SiteUID', 
  'isShowbrTaxhint',
  'CartOrderSFSVisual'
])
const { assignState } = useMapMutations(['assignState'])

const taxHint = computed(() => {
  const { tax = [] } = checkout.value.mall_caculate_info || {}
  const taxList = tax.filter(item => +item.taxPrice?.amount > 0 && item.taxType == 7) || []
  // 当前不会存在配置多条的情况，默认取第一个处理
  const taxPrice = taxList[0]?.taxPrice?.amountWithSymbol || ''
  const taxHint = touchRuleInfo.value?.msg || ''

  return (!!taxList.length && taxHint) ? taxHint.replace('{br_tax_1}', taxPrice) : ''
})

const sfsProducts = computed(() => props.cartsInfo.filter(item => item.sfs_flag == 1))
// 只有一个商品且该商品为 sfs 商品
const isOnlyOneSfs = computed(() => props.cartsInfo.length === 1 && sfsProducts.value.length === 1)
const isAllSfs = computed(() => sfsProducts.value.length === props.cartsInfo.length)
const sfsProductsTipsVisible = computed(() => (
  CartOrderSFSVisual.value.visual_tabs &&
  CartOrderSFSVisual.value.visual_benefit_point &&
    !props.showShoppingBagItemDetail &&
    sfsProducts.value.length !== 0    
))
const sfsProductsTips = computed(() => {
  let tips = ''
  if (isAllSfs.value) {
    tips = language.value.SHEIN_KEY_PC_32656
  } else {
    tips = language.value.SHEIN_KEY_PC_32657
  }
  if (!isOnlyOneSfs.value) tips += ' >'
  return tips
})

const showTaxHint = computed(() => {
  const isBrSite = !!~SiteUID.value?.indexOf('br')

  const { match_goods = [] } = touchRuleInfo.value || {}
  const touchRuleId = match_goods?.map(item => item.id) || []
  let cartInclude =  false
  props.cartsInfo.forEach(cart => {
    if(touchRuleId.includes(cart.id)) cartInclude = true
  })

  return isShowbrTaxhint.value && isBrSite && !!taxHint.value && cartInclude
})

const openSfsProductsDrawer = () => {
  // 仅有一个 sfs 商品时不允许点击
  if (isOnlyOneSfs.value) return
  assignState({
    showSfsProductsDrawer: true,
    sfsProductsDrawerIsLoaded: true
  })
}

const clickTaxHint = () => {
  // 结算车内最后一个商品无点击行为（非mall下）
  if(props.isLastGoods) return

  // 修改购物车状态接口无法正常获取下单页券赠品勾选状态，弹窗内不展示该商品
  const carts = props.cartsInfo.filter(item => item.promotion_type_id != '1000')
  assignState({
    taxCartsInfo: {
      list: carts,
      mallCode: props.mallCode
    },
    showBagTax: true,
    shoppingBagTaxIsLoaded: true
  })
}

</script>
<style lang="less" scoped>
.shopping-bag-tips {
  &__hint {
    padding: 10px 8px;
    margin: 0 16px 16px;
    min-height: unset;
  }

  &__sfs {
    margin: 0 16px 12px;
    font-size: 12px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp:2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    cursor: pointer;
  }

  &__sfs-label {
    padding: 1px 3px;
    background-color: #ECFCF3;
    color: #198055;
    display: inline-block;
    line-height: 1.2;
    border-radius: 2px;
    margin-right: 3px;
    vertical-align: top;
  }

  &__sfs-text {
    color: #666;
  }
}
</style>
