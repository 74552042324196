const DEBUG_PATH = '__quato_shein_debug__'

const INTERVAL_TIME = {
  FAST: 3000,
  COMMON: 5000
}

const MAX_TIMES = 10

export class CustomQuota {
  instance
  isClient = false
  pathName = ''
  intervalTime = INTERVAL_TIME.COMMON
  timeMachine
  maxTimes = MAX_TIMES
  currentTimes = 0
  finishContent = {
    htmlQuota: { finish: false, data: null, check: true },
    netQuota: { finish: false, data: null, check: true },
    deviceQuota: { finish: false, data: null, check: true },
    cssQuota: { finish: false, data: null, check: true, regAry: null },
    jsQuota: { finish: false, data: null, check: true, regAry: null },
    reqQuota: { data: null, observerInstance: null, regAry: null },
    imgGroupQuota: { finish: false, data: null, regAry: null },
    commonQuota: { data: null, observerInstance: null }
  }
  constructor() {
    this
      .setClient()
      .setPathName()
      .setIntervalTime()
      .eventThing()
  }
  static getInstance = () => {
    try {
      if (typeof window !== 'undefined' && window?.CustomQuotaInstance instanceof CustomQuota) {
        this.instance = window.CustomQuotaInstance
      } else if (typeof window !== 'undefined' && !this.instance) {
        this.instance = window.CustomQuotaInstance = new CustomQuota()
      } else if (!this.instance) {
        this.instance = new CustomQuota()
      }
    } catch (e) {
      this.instance = new CustomQuota()
      console.error('CustomQuota getInstance Error', e)
    } finally {
      console.log('CustomQuota this.instance', this.instance)
      return this.instance
    }
  }
  setClient = () => {
    this.isClient = typeof window !== 'undefined'
    return this
  }
  setPathName = (name) => {
    if (!this.isClient) { return this }
    this.pathName = name ?? window?.location?.pathname
    return this
  }
  setIntervalTime = () => {
    if (!this.isClient) { return this }
    const { downlink, rtt } = window?.navigator?.connection ?? {}
    this.intervalTime = downlink >= 5 && rtt <= 300 ? INTERVAL_TIME.FAST : INTERVAL_TIME.COMMON
    return this
  }
  eventThing = () => {
    if (!this.isClient) { return this }
    window.addEventListener('beforeunload', (e) => {
      // 离开页面或刷新清空，防止内存泄露
      const reqObserverInstance = this.finishContent.reqQuota.observerInstance
      const commonObserverInstance = this.finishContent.commonQuota.observerInstance
      reqObserverInstance instanceof PerformanceObserver && reqObserverInstance.disconnect()
      commonObserverInstance instanceof PerformanceObserver && commonObserverInstance.disconnect()
      clearTimeout(this.timeMachine)
    })
    return this
  }
  setQuotaReg = (props) => {
    if (!this.isClient) { return this }
    const { reqRegAry, cssRegAry, jsRegAry, imgGroupRegAry } = props ?? {}
    if (Array.isArray(reqRegAry) && reqRegAry?.length > 0) { this.finishContent.reqQuota.regAry = reqRegAry }
    if (Array.isArray(cssRegAry) && cssRegAry?.length > 0) { this.finishContent.cssQuota.regAry = cssRegAry }
    if (Array.isArray(jsRegAry) && jsRegAry?.length > 0) { this.finishContent.jsQuota.regAry = jsRegAry }
    if (Array.isArray(imgGroupRegAry) && imgGroupRegAry?.length > 0) { this.finishContent.imgGroupQuota.regAry = imgGroupRegAry }
    return this
  }
  getEntries = () => {
    if (!this.isClient) { return [] }
    return window.performance.getEntries()
  }
  getEntriesByType = (...rest) => {
    if (!this.isClient) { return [] }
    return window.performance.getEntriesByType(...rest)
  }
  setConsoleMsg = (props) => {
    if (!this.isClient) { return this }
    const { type, params } = props ?? {}
    const searchPath = new URLSearchParams(window?.location?.search)
    const searchValue = searchPath?.get(DEBUG_PATH)
    const storageValue = window.sessionStorage.getItem(DEBUG_PATH)
    // 如果localStorage中没有值且需要log时，缓存该值
    if (!storageValue && searchValue === 'true') {
      window.sessionStorage.setItem(DEBUG_PATH, searchValue)
    }
    if ([searchValue, storageValue].includes('true')) {
      const prefix = `[CustomQuota debug ${type}]`
      const color = 'background: #000; color: #fff'
      console[type](`%c${prefix}`, color, ...params)
    }
  }
  log = (...rest) => {
    if (!this.isClient) { return this }
    this.setConsoleMsg({ type: 'log', params: rest })
  }
  warn = (...rest) => {
    if (!this.isClient) { return this }
    this.setConsoleMsg({ type: 'warn', params: rest })
  }
  error = (...rest) => {
    if (!this.isClient) { return this }
    this.setConsoleMsg({ type: 'error', params: rest })
  }
  /**
   * 报错字符串
   * @param {name} string 方法名，不使用function.name是因为编译后方法名不可控
   * @returns string
   */
  getErrorMsg = (name, error) => {
    if (!this.isClient) { return this }
    let resStr = `${name} Error`
    if (this.pathName) {
      resStr += `: ${this.pathName}`
    }
    this.error(resStr, error)
  }
  checkAllFinish = () => Object.values(this.finishContent)?.filter(item => item.check)?.every(flag => flag?.finish)
  markPoint = (key) => {
    let resObj = {}
    try {
      if (!this.isClient) { return resObj }
      if (key && typeof key === 'string') {
        resObj = { success: true, data: window.performance.mark(key) }
      }
    } catch (e) {
      this.getErrorMsg('markPoint', e)
      resObj = { success: false, data: {} }
    } finally {
      return resObj
    }
  }
  /**
   * 发送埋点请求
   * @param {props.data} []{ path: string; num: number }
   * @returns 
   */
  sendPointReq = (props) => {
    try {
      if (!this.isClient) { return this }
      const { data, options = {} } = props ?? {}
      const pathName = this.pathName
      if (pathName && Array.isArray(data) && data?.length > 0) {
        const formatData = data.map(item => ({
          key_path: `${pathName}${item?.path}`,
          values: { num: item?.num }
        }))
        this.log('sendPointReq 发送埋点请求', formatData)
        typeof window?.clientAnalytics?.defineTrack === 'function' && window?.clientAnalytics?.defineTrack({
          data: formatData
        }, {
          random: 0.5,
          immediate: true,
          ...(typeof options === 'object' ? options : {})
        })
      }
    } catch (e) {
      this.getErrorMsg('sendPointReq', e)
    } finally {
      return this
    }
  }
  getHtmlQuota = () => {
    try {
      if (!this.isClient || this.finishContent.htmlQuota.finish) { return this }
      const htmlSource = this.getEntriesByType('navigation')?.[0]
      const {
        name,
        activationStart,
        requestStart,
        responseStart,
        responseEnd,
        duration,
        loadEventEnd,
        encodedBodySize,
        decodedBodySize,
        transferSize,
        redirectCount,
        redirectStart,
        redirectEnd,
        workerStart,
        fetchStart,
        domainLookupStart,
        domainLookupEnd,
        connectStart,
        connectEnd
      } = htmlSource ?? {}
      // 一般duration不是第一次就能取到的，以他为重点获取
      // if (name && (new URL(name))?.pathname === this.pathName && duration > 0) {
      if (name && duration > 0) {
        const data = [
          { path: '-html~requestStart', num: requestStart },
          { path: '-html~responseStart', num: responseStart },
          { path: '-html~responseEnd', num: responseEnd },
          { path: '-html~requestDuration', num: Math.abs(responseStart - requestStart) },
          { path: '-html~downloadDuration', num: Math.abs(responseEnd - responseStart) },
          { path: '-html~parseDuration', num: Math.abs(loadEventEnd - responseEnd) },
          { path: '-html~duration', num: duration },
          { path: '-html~encodedBodySize', num: encodedBodySize / 10240 },
          { path: '-html~decodedBodySize', num: decodedBodySize / 10240 },
          { path: '-html~transferSize', num: transferSize / 10240 },
          { path: '-html~redirectCount', num: redirectCount * 100 },
          { path: '-html~redirectStart', num: redirectStart },
          { path: '-html~redirectEnd', num: redirectEnd },
          { path: '-html~redirectDuration', num: Math.abs(redirectEnd - redirectStart) },
          { path: '-html~workerStart', num: workerStart },
          { path: '-html~fetchStart', num: fetchStart },
          { path: '-html~serviceWorkerInit', num: Math.abs(fetchStart - workerStart) },
          { path: '-html~domainLookupStart', num: domainLookupStart },
          { path: '-html~domainLookupEnd', num: domainLookupEnd },
          { path: '-html~dnsDuration', num: Math.abs(domainLookupEnd - domainLookupStart) },
          { path: '-html~connectStart', num: connectStart },
          { path: '-html~connectEnd', num: connectEnd },
          { path: '-html~tcpDuration', num: Math.abs(connectEnd - connectStart) },
          { path: '~TTFB', num: Math.abs(responseStart - activationStart) }
        ]
        this.finishContent.htmlQuota.finish = true
        this.finishContent.htmlQuota.data = data
        this.sendPointReq({ data })
      }
    } catch (e) {
      this.getErrorMsg('getHtmlQuota', e)
    } finally {
      return this
    }
  }
  getNetQuota = () => {
    try {
      if (!this.isClient || this.finishContent.netQuota.finish) { return this }
      const { downlink, rtt } = window?.navigator?.connection ?? {}
      if (downlink > 0 && rtt > 0) {
        const data = [
          { path: '~downlink', num: downlink * 100 },
          { path: '~rtt', num: rtt },
        ]
        this.finishContent.netQuota.finish = true
        this.finishContent.netQuota.data = data
        this.sendPointReq({ data })
      }
    } catch (e) {
      this.getErrorMsg('getNetQuota', e)
    } finally {
      return this
    }
  }
  getDeviceQuota = () => {
    try {
      if (!this.isClient || this.finishContent.deviceQuota.finish) { return this }
      const { deviceMemory, hardwareConcurrency } = window?.navigator ?? {}
      // deviceMemory：返回设备内存的近似值（单位：G）
      // hardwareConcurrency：返回用户计算机上可用于运行线程的逻辑处理器数量
      if (deviceMemory > 0 && hardwareConcurrency > 0) {
        const data = [
          { path: '~deviceMemory', num: deviceMemory * 100 },
          { path: '~hardwareConcurrency', num: hardwareConcurrency * 100 },
        ]
        this.finishContent.deviceQuota.finish = true
        this.finishContent.deviceQuota.data = data
        this.sendPointReq({ data })
      }
    } catch (e) {
      this.getErrorMsg('getDeviceQuota', e)
    } finally {
      return this
    }
  }
  getReqQuota = () => {
    try {
      if (!this.isClient || !Array.isArray(this.finishContent.reqQuota.regAry)) { return this }
      if (this.finishContent.reqQuota.observerInstance instanceof PerformanceObserver) { this.log('reqQuota的observer已经添加，不继续执行'); return this }
      const observer = new PerformanceObserver((entryList) => {
        const entryListAry = entryList.getEntries()
        const data = []
        const regAry = this.finishContent.reqQuota.regAry
        entryListAry.forEach(item => {
          const booleanAry = regAry.map(reg => (new RegExp(reg)).test(item.name))
          const booleanAryTrueIndex = booleanAry.indexOf(true)
          if (booleanAryTrueIndex > -1) {
            const { requestStart, responseStart, responseEnd, duration } = item ?? {}
            data.push({ path: `-${regAry[booleanAryTrueIndex]}~requestDuration`, num: Math.abs(responseStart - requestStart) })
            data.push({ path: `-${regAry[booleanAryTrueIndex]}~downloadDuration`, num: Math.abs(responseEnd - responseStart) })
            data.push({ path: `-${regAry[booleanAryTrueIndex]}~duration`, num: duration })
          }
        })
        if (Array.isArray(data) && data?.length > 0) {
          this.log('getReqQuota data', data)
          this.sendPointReq({ data })
          this.finishContent.reqQuota.data = Array.isArray(this.finishContent.reqQuota.data) ? [...this.finishContent.reqQuota.data, ...data] : data
        }
      })
      observer.observe({ type: 'resource', buffered: true })
      this.finishContent.reqQuota.observerInstance = observer
    } catch (e) {
      this.getErrorMsg('getReqQuota', e)
    } finally {
      return this
    }
  }
  // 因为LCP改变频发触发observer，能会多次发送埋点的方法
  // 由于轮询会进入，所以需要判断observerInstance是否存在，单例执行
  // if的各种阻塞只是阻塞创建observerInstance，而不是disconnect掉observerInstance
  getCommonQuota = () => {
    try {
      if (!this.isClient) { return this }
      if (this.finishContent.commonQuota.observerInstance instanceof PerformanceObserver) { this.log('commonQuota的observer已经添加，不继续执行'); return this }
      const observer = new PerformanceObserver((entryList) => {
        const paintAry = this.getEntriesByType('paint')
        const entryListAry = entryList.getEntries()
        this.log('getCommonQuota LCP entryList', entryListAry, entryListAry[entryListAry?.length - 1]?.startTime, entryListAry[entryListAry?.length - 1]?.element)
        const FP = paintAry?.filter(entry => entry.name == 'first-paint')?.[0]?.startTime
        const FCP = paintAry?.filter(entry => entry.name == 'first-contentful-paint')?.[0]?.startTime
        const LCP = entryListAry[entryListAry?.length - 1]?.startTime
        const data = [
          { path: '~FP', num: FP },
          { path: '~FCP', num: FCP },
          { path: '~LCP', num: LCP },
          { path: '~FCP-FP', num: Math.abs(FCP - FP) },
          { path: '~LCP-FCP', num: Math.abs(LCP - FCP) }
        ]
        this.finishContent.commonQuota.data = data
        this.sendPointReq({ data })
        // LCP可能会因为rerender重新获取上报，这里不取消监听防止后续上报被阻塞
        // observer.disconnect()
      })
      observer.observe({ type: 'largest-contentful-paint', buffered: true })
      this.finishContent.commonQuota.observerInstance = observer
    } catch (e) {
      this.getErrorMsg('getCommonQuota', e)
    } finally {
      return this
    }
  }
  sourceCommonLogic = ({ sourceAry, sucCallback }) => {
    try {
      if (!this.isClient) { return this }
      if (Array.isArray(sourceAry) && sourceAry?.length > 0) {
        const data = []
        sourceAry.forEach(item => {
          const { name, requestStart, responseStart, responseEnd, duration, encodedBodySize, decodedBodySize, transferSize } = item ?? {}
          // 统计的path中不带hash部分，保证APM平台key一致
          const pathName = (new URL(name))?.pathname?.replace(/-[0-9a-f]+/, '')
          data.push({ path: `-${pathName}~requestDuration`, num: Math.abs(responseStart - requestStart) })
          data.push({ path: `-${pathName}~downloadDuration`, num: Math.abs(responseEnd - responseStart) })
          data.push({ path: `-${pathName}~duration`, num: duration })
          data.push({ path: `-${pathName}~encodedBodySize`, num: encodedBodySize / 10240 })
          data.push({ path: `-${pathName}~decodedBodySize`, num: decodedBodySize / 10240 })
          data.push({ path: `-${pathName}~transferSize`, num: transferSize / 10240 })
        })
        typeof sucCallback === 'function' && sucCallback(data)
      }
    } catch (e) {
      this.getErrorMsg('sourceCommonLogic', e)
    } finally {
      return this
    }
  }
  getJsQuota = () => {
    try {
      if (!this.isClient || this.finishContent.jsQuota.finish) { return this }
      if (!this.finishContent.jsQuota.regAry) {
        this.log('未设置JS正则规则，JS资源不获取&不上报')
        this.finishContent.jsQuota.finish = true
        return this
      }
      this.sourceCommonLogic({
        sourceAry: this.getEntries()?.filter(item => this.finishContent.jsQuota.regAry.map(reg => (new RegExp(reg)).test(item?.name) && item?.name?.endsWith('js')).includes(true)),
        sucCallback: (data) => {
          this.finishContent.jsQuota.finish = true
          this.finishContent.jsQuota.data = data
          this.sendPointReq({ data })
        }
      })
    } catch (e) {
      this.getErrorMsg('getJsQuota', e)
    } finally {
      return this
    }
  }
  getCssQuota = () => {
    try {
      if (!this.isClient || this.finishContent.cssQuota.finish) { return this }
      if (!this.finishContent.cssQuota.regAry) {
        this.log('未设置CSS正则规则，CSS资源不获取&不上报')
        this.finishContent.cssQuota.finish = true
        return this
      }
      this.sourceCommonLogic({
        sourceAry: this.getEntries()?.filter(item => this.finishContent.cssQuota.regAry.map(reg => (new RegExp(reg)).test(item?.name) && item?.name?.endsWith('css')).includes(true)),
        sucCallback: (data) => {
          this.finishContent.cssQuota.finish = true
          this.finishContent.cssQuota.data = data
          this.sendPointReq({ data })
        }
      })
    } catch (e) {
      this.getErrorMsg('getCssQuota', e)
    } finally {
      return this
    }
  }
  formatImgGroupQuota = (prefixName, sourceObj) => {
    let defaultReturnObj = [false, []]
    let returnObj = [false, []]
    try {
      const {
        name,
        requestStart,
        responseStart,
        responseEnd,
        duration,
        redirectStart,
        redirectEnd,
        startTime,
        workerStart,
        fetchStart,
        domainLookupStart,
        domainLookupEnd,
        connectStart,
        connectEnd
      } = sourceObj ?? {}
      if (name && duration > 0) {
        returnObj[0] = true
        returnObj[1] = [
          { path: `-imgGroup~${prefixName}~index`, num: this.getEntries()?.findIndex(item => item.name === sourceObj?.name) * 100 }, // 获取当前资源的索引，如果较大，就可以从优先级入手
          { path: `-imgGroup~${prefixName}~requestStart`, num: requestStart },
          { path: `-imgGroup~${prefixName}~responseStart`, num: responseStart },
          { path: `-imgGroup~${prefixName}~responseEnd`, num: responseEnd },
          { path: `-imgGroup~${prefixName}~requestDuration`, num: Math.abs(responseStart - requestStart) },
          { path: `-imgGroup~${prefixName}~downloadDuration`, num: Math.abs(responseEnd - responseStart) },
          { path: `-imgGroup~${prefixName}~duration`, num: duration },
          { path: `-imgGroup~${prefixName}~redirectStart`, num: redirectStart },
          { path: `-imgGroup~${prefixName}~redirectEnd`, num: redirectEnd },
          { path: `-imgGroup~${prefixName}~redirectDuration`, num: Math.abs(redirectEnd - redirectStart) },
          { path: `-imgGroup~${prefixName}~startTime`, num: startTime },
          { path: `-imgGroup~${prefixName}~prepareRequest`, num: Math.abs(requestStart - startTime) },
          { path: `-imgGroup~${prefixName}~workerStart`, num: workerStart },
          { path: `-imgGroup~${prefixName}~fetchStart`, num: fetchStart },
          { path: `-imgGroup~${prefixName}~serviceWorkerInit`, num: Math.abs(fetchStart - workerStart) },
          { path: `-imgGroup~${prefixName}~domainLookupStart`, num: domainLookupStart },
          { path: `-imgGroup~${prefixName}~domainLookupEnd`, num: domainLookupEnd },
          { path: `-imgGroup~${prefixName}~dnsDuration`, num: Math.abs(domainLookupEnd - domainLookupStart) },
          { path: `-imgGroup~${prefixName}~connectStart`, num: connectStart },
          { path: `-imgGroup~${prefixName}~connectEnd`, num: connectEnd },
          { path: `-imgGroup~${prefixName}~tcpDuration`, num: Math.abs(connectEnd - connectStart) },
        ]
        returnObj[2] = requestStart
        returnObj[3] = responseEnd
        returnObj[4] = startTime
      }
    } catch (e) {
      returnObj = defaultReturnObj
      this.getErrorMsg('formatImgGroupQuota', e)  
    } finally {
      return returnObj
    }
  }
  getImgGroupQuota = () => {
    try {
      if (!this.isClient || this.finishContent.imgGroupQuota.finish) { return this }
      if (!this.finishContent.imgGroupQuota.regAry) {
        this.log('未设置ImgGroup正则规则，ImgGroup资源不获取&不上报')
        this.finishContent.imgGroupQuota.finish = true
        return this
      }
      this.log('imgGroupQuota.regAry', this.finishContent.imgGroupQuota.regAry)
      const sourceAry = this.getEntries()?.filter(item => this.finishContent.imgGroupQuota.regAry.map(reg => (new RegExp(reg)).test(item?.name)).includes(true))
      let firstSourceObj = {}
      let lastSourceObj = {}
      if (Array.isArray(sourceAry) && sourceAry?.length > 0) {
        firstSourceObj = sourceAry[0]
        lastSourceObj = sourceAry[sourceAry.length - 1]
      }
      const [firstSuccess, firstData, firstRequestStart, ,firstStartTime] = this.formatImgGroupQuota('first', firstSourceObj)
      const [lastSuccess, lastData, lastRequestStart, lastResponseEnd] = this.formatImgGroupQuota('last', lastSourceObj)
      if (firstSuccess && lastSuccess) {
        const data = [
          ...firstData, 
          ...lastData, 
          { path: `-imgGroup~startInterval`, num: Math.abs(lastRequestStart - firstRequestStart) },
          { path: `-imgGroup~groupDuration`, num: Math.abs(lastResponseEnd - firstStartTime) }
        ]
        this.finishContent.imgGroupQuota.finish = true
        this.finishContent.imgGroupQuota.data = data
        this.sendPointReq({ data })
      }
    } catch (e) {
      this.getErrorMsg('getImgGroupQuota', e)
    } finally {
      return this
    }
  }
  sendBatchData = () => {
    try {
      if (!this.isClient) { return this }
      this.log('sendBatchData start')
      clearTimeout(this.timeMachine)
      const logFinishKey = Object.keys(this.finishContent)
      const logFinishContent = Object.values(this.finishContent)
      if (this.checkAllFinish()) {
        this.currentTimes = 0
        this.log('sendBatchData 轮询停止 & 自动化模式所有上报完成', logFinishKey, logFinishContent);
        return this
      }
      if (this.currentTimes > this.maxTimes) { this.warn('sendBatchData 轮询次数耗尽', logFinishKey, logFinishContent); return this }
      this.log(`sendBatchData ${this.currentTimes === 0 ? '首次' : `轮询${this.currentTimes}次`}`, this.intervalTime, logFinishKey, logFinishContent)
      this.getHtmlQuota()
      this.getNetQuota()
      this.getDeviceQuota()
      this.getCommonQuota()
      this.getReqQuota()
      this.getJsQuota()
      this.getCssQuota()
      this.getImgGroupQuota()
      this.currentTimes += 1
      this.timeMachine = setTimeout(() => {
        this.sendBatchData()
      }, this.intervalTime)
      return this
    } catch (e) {
      this.getErrorMsg('sendBatchData', e)
    } finally {
      return this
    }
  }
}
