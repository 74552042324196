<template>
  <div class="checkout-cart__content">
    <!-- 纯非qs购物袋 -->
    <div
      v-if="!quickShipCarts.length"
      class="checkout-cart__effiency"
    >
      <CheckoutCartList 
        :carts-data="noQuickShipCarts"
        :chemicals-ids="chemicalsIds"
        :tag-ids-map="tagIdsMap"
        :can-edit-shoppingbag="canEditShoppingbag"
        :is-last-goods="isLastGoodsWithQs"
        :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
        :discount-logo-by-abt="discountLogoByAbt"
        :under-price-abt-value="underPriceAbtValue"
        :is-suggested="isSuggested"
        :checkout-scene="checkoutScene"
        is-qs-product="0"
        :large-ship-extends-info="largeShipExtendsInfo"
        :is-site-mall-list="isSiteMallList"
      />
    </div>
    <!-- 具有qs购物袋和非qs购物袋 或 纯非qs -->
    <template v-else>
      <!-- 纯qs购物袋 -->
      <div
        v-if="!noQuickShipCarts.length"
        class="checkout-cart__effiency"
      >
        <div class="checkout-cart__effiency-tips">
          <template v-if="quickShipCheckoutStatus.quickShipStyle != 1">
            {{ template(quickShipCheckoutStatus.quickShipLabelText, language.SHEIN_KEY_PC_30623) }}
          </template>
          <template v-else>
            <div class="quickShip-tage">
              <quickShipLabel
                :icon-style-config="{
                  size: '16px'
                }"
              />
              <span>All items</span>
            </div>
          </template>
        </div>
        <CheckoutCartList 
          :carts-data="quickShipCarts"
          :chemicals-ids="chemicalsIds"
          :tag-ids-map="tagIdsMap"
          :can-edit-shoppingbag="canEditShoppingbag"
          :is-last-goods="isLastGoodsWithQs"
          :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
          :discount-logo-by-abt="discountLogoByAbt"
          :under-price-abt-value="underPriceAbtValue"
          :is-suggested="isSuggested"
          :checkout-scene="checkoutScene"
          is-qs-product="1"
          :large-ship-extends-info="largeShipExtendsInfo"
          :is-site-mall-list="isSiteMallList"
        />
      </div>
      <!-- 部分qs -->
      <div
        v-else
        class="checkout-cart__alltype"
      >
        <div class="checkout-cart__effiency checkout-cart__alltype-item">
          <div class="checkout-cart__effiency-tips j-checkout-qstips">
            <template v-if="!quickShipCheckoutStatus.quickShipStyle">
              {{ template(cartNumTotal(quickShipCarts), quickShipCheckoutStatus.quickShipLabelText, language.SHEIN_KEY_PC_30625) }}
            </template>
            <template v-else>
              <div class="quickShip-tage">
                <quickShipLabel 
                  v-if="showQsFreight"
                  :style-config="{ 'font-size': '13px'}"
                  :icon-style-config="{ size: '16px' }"
                />
                <span>{{ template(cartNumTotal(quickShipCarts), `{0} ${language.SHEIN_KEY_PC_15448}`) }}</span>
              </div>
            </template>
            <template v-if="showQsFreight">
              <!-- qs需要运费 提示 -->
              <s-radio
                v-model="freightChecked"
                size="radio20"
                :toggle="true"
                :label="true"
                :inline="false"
                :disabled="checkoutScene != 'Checkout'"
                theme="icon"
                gap="0"
                class="checkout-cart__effiency-freight"
                @click.stop.prevent="toggleQsFreight"
              >
                <div class="checkout-effiency__freight">
                  <div class="checkout-effiency__freight-name">
                    <span v-html="qsFreightInfo.qs_freight_name"></span>
                    <s-popover
                      v-if="qsFreightInfo.qs_freight_tips"
                      :hide-close-icon="true"
                      placemen="bottom"
                      trigger="hover"
                    >
                      <div v-html="qsFreightInfo.qs_freight_tips"></div>
                      <template #reference>
                        <sui_icon_doubt_12px_2 
                          size="13px" 
                          color="#666666"
                        />
                      </template>
                    </s-popover>
                  </div>
                  <div
                    v-if="descQuickShipTime"
                    class="checkout-cart__effiency-quicktime"
                    @click.stop="showQuickPop"
                    v-html="descQuickShipTime"
                  ></div>
                </div>
              </s-radio>
            </template>
            <template v-else>
              <!-- qs不需要运费 提示 -->
              <template v-if="!quickShipCheckoutStatus.quickShipStyle">
                <!-- 老样式 -->
                <div
                  v-if="descQuickShipTime"
                  class="checkout-cart__effiency-quicktime"
                  @click.stop="showQuickPop"
                >
                  <span v-html="descQuickShipTime"></span>
                </div>
              </template>
              <!-- 新样式 -->
              <quickShipFreeTips
                v-else
                :carts-data="cartsInfo"
                :shipping-method="shippingMethod"
                @open-modal="openModal"
              />
            </template>
          </div>
          <CheckoutCartList 
            :carts-data="quickShipCarts"
            :chemicals-ids="chemicalsIds"
            :tag-ids-map="tagIdsMap"
            :can-edit-shoppingbag="canEditShoppingbag"
            :is-last-goods="isLastGoodsWithQs"
            :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
            :discount-logo-by-abt="discountLogoByAbt"
            :under-price-abt-value="underPriceAbtValue"
            :is-suggested="isSuggested"
            :checkout-scene="checkoutScene"
            is-qs-product="1"
            :large-ship-extends-info="largeShipExtendsInfo"
            :is-site-mall-list="isSiteMallList"
          />
        </div>
        <div class="checkout-cart__effiency checkout-cart__alltype-item">
          <div class="checkout-cart__effiency-tips j-checkout-noqstips">
            {{ template(cartNumTotal(noQuickShipCarts),language.SHEIN_KEY_PC_23552) }}
            <!-- 运输方式 qs 时效文案 -->
            <div
              v-if="shippingDescribe"
              class="bag-shipping-describe"
            >
              {{ shippingDescribe }}
            </div>
          </div>
          <CheckoutCartList 
            :carts-data="noQuickShipCarts"
            :chemicals-ids="chemicalsIds"
            :tag-ids-map="tagIdsMap"
            :can-edit-shoppingbag="canEditShoppingbag"
            :is-last-goods="isLastGoodsWithQs"
            :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
            :discount-logo-by-abt="discountLogoByAbt"
            :under-price-abt-value="underPriceAbtValue"
            :is-suggested="isSuggested"
            :checkout-scene="checkoutScene"
            is-qs-product="0"
            :large-ship-extends-info="largeShipExtendsInfo"
            :is-site-mall-list="isSiteMallList"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CheckoutCartList from './CheckoutCartList'

import { template as _commonTemplate } from '@shein/common-function'
import { mapGetters, mapState } from 'vuex'
import quickShipLabel from 'public/src/pages/checkout/components/atom/quickShipLabel.vue'
import { sui_icon_doubt_12px_2 } from '@shein-aidc/icon-vue3'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { shippingDescribeFn } from 'public/src/pages/checkout/utils.js'
import quickShipFreeTips from 'public/src/pages/checkout/components/atom/quickShipFreeTips.vue'

export default {
  name: 'CheckoutCartContent',
  components: {
    CheckoutCartList,
    quickShipLabel,
    sui_icon_doubt_12px_2,
    quickShipFreeTips,
  },
  props: {
    cartsInfo: {
      type: Array,
      default: () => []
    },
    quickShipCarts: {
      type: Array,
      default: () => []
    },
    noQuickShipCarts: {
      type: Array,
      default: () => []
    },
    chemicalsIds: {
      type: Array,
      default: () => []
    },
    tagIdsMap: {
      type: Object,
      default: () => {}
    },
    showQsFreight: {
      type: Boolean,
      default: false
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    freightChecked: {
      type: Boolean,
      default: false
    },
    checkoutScene: {
      type: String,
      default: ''
    },
    qsFreightInfo: {
      type: Object,
      default: () => {}
    },
    canEditShoppingbag: {
      type: Boolean,
      default: false
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
    shoppingBagCrossedPriceByAbt: {
      type: Boolean,
      default: false
    },
    discountLogoByAbt: {
      type: Boolean,
      default: false
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: [Boolean, String],
      default: false
    },
    largeShipExtendsInfo: {
      type: Object,
      default: () => {}
    },
    isSiteMallList: {
      type: Boolean,
      default: false
    },
    shippingMethod: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters([
      'switchOnEvoluSHEIN',
      'EVOLU_SHEIN_ID',
      'quickShipCheckoutStatus',
      'timePercentAbt',
    ]),
    ...mapState([
      'checkout', 
      'language',
      'quickShipInfoTag',
      'defaultShipping'
    ]),
    // qs拆车算不同的商品
    isLastGoodsWithQs() {
      const listLen = this.quickShipCarts.length + this.noQuickShipCarts.length
      const noSplit = listLen == this.cartsInfo.length

      return this.isLastGoods && noSplit
    },
    // 当前mall维度下的运输方式，qs时效提示
    shippingDescribe () {
      const langNum = {
        1: 'SHEIN_KEY_PC_31369',
        2: 'SHEIN_KEY_PC_31370',
        3: 'SHEIN_KEY_PC_31371'
      }
      const { desc_type, date_type, shipping_time, aging_date } = this.shippingMethod || {}
      const num = desc_type == 0 ? (date_type == 1 ?  1 : 2) : 3
      const date = num == 3 ? aging_date : shipping_time
      let str = _commonTemplate(date, this.language[langNum[num]])
      return date ? str : ''
      // return shippingDescribeFn({ item: this.shippingMethod, timePercentAbt: this.timePercentAbt, quickShipInfoTag: this.quickShipInfoTag })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setTipsHeight()
    })
 
    window.addEventListener('resize', this.setTipsHeight)
  },
  updated () {
    this.$nextTick(() => {
      this.setTipsHeight()
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setTipsHeight)
  },
  methods: {
    openModal(){
      this.$emit('openModal')
    },
    getExposeData: function() {
      // abt控制evoluSHEIN打开，且有evoluSHEIN标签的商品才上报事件
      if(this.switchOnEvoluSHEIN) {
        let tag_info = '', isSendEvent = false
        this.cartsInfo && Array.isArray(this.cartsInfo) && this.cartsInfo.forEach(item => {
          if(item?.rules?.includes(this.EVOLU_SHEIN_ID)) {
            isSendEvent = true
            tag_info === '' ? tag_info += `${item?.product?.sku_code}${'`'}show_evolushein` : tag_info += `,${item?.product?.sku_code}${'`'}show_evolushein`
          }
        })
        isSendEvent && daEventCenter.triggerNotice({
          daId: '1-11-1-222',
          extraData: {
            postion: 'page',
            tag_info
          }
        })
      }
    },
    template: _commonTemplate,
    cartNumTotal: function (carts) {
      if( !carts.length ) return ''
      let total = carts.reduce((total, item)=> {
        total = total + Number(item.quantity)
        return total
      }, 0)
      return total
    },
    showQuickPop() {
      this.$emit('showQuickPop')
    },
    toggleQsFreight() {
      this.$emit('toggleQsFreight')
    },
    setTipsHeight() {
      const tipsHeight = document.querySelector('.j-checkout-qstips')?.clientHeight || 0
      const noQSTips = document.querySelector('.j-checkout-noqstips')
      const baQsTime_detai = document.querySelector('.bag-quick-time')?.clientHeight || 0
      const bagShippingDescribe = document.querySelector('.bag-shipping-describe')?.clientHeight || 0
      if (!noQSTips) return 
      noQSTips.style.minHeight = `${tipsHeight}px`
      if(baQsTime_detai){
        bagShippingDescribe.style.minHeight = `${baQsTime_detai}px`
      }
    },
  },
  emits: ['showQuickPop', 'toggleQsFreight', 'openModal']
}
</script>

<style lang="less" scoped>
.checkout-cart__content {
  margin: 0 16px;
}
.checkout-cart__alltype {
  display: flex;
  width: 100%;
  .checkout-cart__alltype-item {
    width: 50%;
    &:last-child{
      .margin-l(8px);
    }
  }
}
.checkout-cart__effiency {
  background: #F9F9F9;
  padding: 10px;
  
  .checkout-cart__effiency-tips {
    font-size: 16px;
    color: #222;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .bag-shipping-describe{
    background: #F6F6F6;
    padding: 3px;
    margin: 4px 0 5px 0;
    box-sizing: border-box;
    border-radius: 2px;
    border: 0.5px solid var(---sui_color_gray_weak1, #E5E5E5);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: 1.3;
    }
  // .checkout-cart_quickShipWord{
  //   display: flex;
  //   height: 16px;
  //   padding: 1px 3px;
  //   align-items: center;
  //   border-radius: 2px;
  //   background: var(---sui_color_shipping_bg, #F3FAF6);
  // }
  .checkout-cart__effiency-quicktime {
    font-size: 12px;
    font-weight: normal;
    margin-top: 4px;
    cursor: pointer;
  }

  .checkout-cart__effiency-freight {
    display: flex;
    margin-top: 4px;
    font-size: 14px;
    font-weight: bold;

    :deep(.sui-radio__check) {
      flex-shrink: 0;
      .margin-r(8px);
    }

    .checkout-effiency__freight {
      white-space: normal;
    }
  }
  .sui-radio {
    margin-bottom: 0;
  }
}
.quickShip-tage{
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  span{
    margin-left: 7px;
  }
}
</style>
