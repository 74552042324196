import { ref, computed } from 'vue'
import { isNumber, isString } from '@shein/common-function'

export default (props) => {

  const { abt = {}, carts = [] } = props
  const onlyLeftLimit = abt?.onlynleft || 10
  const almostSoldoutLimit = abt?.almostsoldout || 14

  const isNum = (value) => {
    if (isString(value)) {
      // 若 value 为字符串，先排除空字符串，再转换为数字，判断是否为数字
      return value && !Number.isNaN(Number(value))
    }
    return isNumber(value)
  }

  const isStockReason = ref(false)

  const lowStockProducts = computed(() => {
    const stockCondition = (item) => {
      // 若 onlynleft（abt）不满足条件，上限取固定值 10
      const stockLimit = isNum(onlyLeftLimit) ? Number(onlyLeftLimit) : 10
      if (item.product.real_stock <= stockLimit) {
        // 若库存满足条件，则为因库存选取商品。只有库存不满足条件，仅由销售天数选取商品时才为 SaleDays
        isStockReason.value = true
        item.product.isStockReason = true
        return true
      }
      return false
    }
    // 若 almostsoldout（abt） 不满足条件，直接返回 false，不再进行该条件判断
    const saleDaysCondition = (item) => {
      // 3213：商品的7日总销量
      const sevenDaysSaleId = '3213'
      const sales = item.product.realTimeTspLabels?.[sevenDaysSaleId] || 0
      const availableSaleDays = Math.round(item.product.real_stock / (sales / 7) * 100) / 100
      return isNum(almostSoldoutLimit) && availableSaleDays <= Number(almostSoldoutLimit)
    }
    return carts?.filter(item => (
      stockCondition(item) || saleDaysCondition(item)
    )) || []
  })

  return {
    lowStockProducts,
    // true: 因库存满足条件; false: 仅因销售天数满足条件
    isStockReason
  }
}
