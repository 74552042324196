<template>
  <div
    class="quickShip"
    :style="styleCustomize"
  >
    <QuickShipTag
      :cart-qs-tag-info="quickShipTagProps"
      :qs-mall-list="qsMallList"
      :last-qs-time="true"
    />
    <div 
      v-if="showLine" 
      class="quickShipLine" 
    ></div>
  </div>
</template>
<script setup>
import QuickShipTag from 'public/src/pages/cart_new/components/list/newList/QuickShipTag.vue'
import { useGetGuickShip } from 'public/src/pages/checkout/hooks/useQuickShip.js'
import { computed } from 'vue'
const { quickShipInfoTag, qsMallList } = useGetGuickShip()

const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => ({})
  },
  iconStyleConfig: {
    type: Object,
    default: () => ({})
  },
  text: {
    type: String,
    default: 'quickShip'
  },
  showLine: {
    type: Boolean,
    default: false 
  }
})

const styleCustomize = {
  ...props.styleConfig
}

const quickShipTagProps = computed(() => {
  const result = props.showLine ? 
    { 
      ...quickShipInfoTag.value?.qsDataNoLabelProps,
      themVarIcon: { color: '#198055', size: '16px', ...props.iconStyleConfig }, 
      themVarText: { color: '#198055', 'font-weight': '590', ...props.styleConfig } 
    } : { 
      ...quickShipInfoTag.value?.qsDataProps,
      themVarIcon: props.iconStyleConfig, 
      themVarText: props.styleConfig
    }
  return result
})

</script>
<style lang="less">
.quickShip{
  display: flex;
  width: fit-content;
  max-width: 100%;
  padding: 1px 3px;
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  font-family: "SF Pro";
}
.quickShipText{
  font-style: italic;
  margin-left: 2px;
  padding-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // word-break: break-all;
  // white-space: normal;
}
.quickShipLine {
  width: 1px;
  height: 14px;
  background: #19805566;
  margin-left: 6px;
  display: flex;
  flex-shrink: 0;
}
</style>
