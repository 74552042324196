import { asyncLoadFile } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import apiReport from 'public/src/pages/common/apiReport'
import mitt from 'mitt'
// const { IS_RW } = gbCommonInfo

const paidyPayEventCenter = mitt()

class PaidyPay {
  scene = '' // 场景 CheckoutAgain, BuyPrime, GiftCard, GiftCardAgain, Checkout

  static eventCenter = paidyPayEventCenter

  paymentCode = ''
  orderInfo = {}
  channelTradeConfig = {}
  paidyConfig = {
    api_key: '',
    token: {
      type: ''
    },
    on_close: (callbackData) => {
      // console.log('paidy callbackData：', callbackData)
      const { status, id } = callbackData || {}
      if (status === 'active' && id) { // status = closed 关闭弹窗
        PaidyPay.eventCenter.emit('paidy-pay-complete', callbackData)
      } else {
        PaidyPay.eventCenter.emit('paidy-pay-cancel')
      }
      // 回参为null，或status=active时未正确返回id则上报
      if (!callbackData || (status === 'active' && !id)) {
        this._sendReport({
          apiPath: 'paidy/fail_to_get_token',
          extraData: {
            callbackData: JSON.stringify(callbackData || {})
          }
        })
      }
    }
  }


  constructor({ scene = '' }) {
    this.scene = scene
    this._removeListener()
    // this._initListeners()
  }

  // _handleMessageEvent (event) {
  //   if (event?.origin?.indexOf('paidy') !== -1) {
  //     const data = event.data || {}
  //     const payload = data.payload || {}
  //     // 关闭验证弹窗
  //     if (data.event === 'paidy_checkout:close' && payload.status !== 'active') {
  //       console.log('paidy checkout close')
  //       PaidyPay.eventCenter.emit('paidy-pay-cancel')
  //     }
  //     console.log('message：', event)
  //   }
  // }

  // _initListeners() {
  //   window.removeEventListener('message', this._handleMessageEvent, false)
  //   window.addEventListener('message', this._handleMessageEvent, false)
  // }

  /**
   * 清除事件，防止在单页应用中触发多次监听
   *
   * @memberof PaidyPay
   */
  _removeListener() {
    // const paidyPayEvents = [
    //   'paidy-pay-launch',
    //   'paidy-pay-complete',
    //   'paidy-pay-error',
    //   'paidy-pay-cancel'
    // ]
    // paidyPayEvents.forEach(evt => {
    //   PaidyPay.eventCenter.$off(evt)
    // })
    if (typeof PaidyPay.eventCenter === 'undefined') return
    PaidyPay.eventCenter.all.clear()
  }

  _sendReport({ apiPath, extraData }) {
    apiReport.report({
      apiPath,
      billno: this.orderInfo?.billno || '',
      paymentMethod: this.paymentCode,
      api_key: this.channelTradeConfig?.public_key || '',
      token_type: this.channelTradeConfig?.channelTokenType || '',
      buyer_name: this.channelTradeConfig?.billingName || '',
      ...(extraData || {})
    })
  }

  asyncLoadSDK(attrs = {}) {
    return asyncLoadFile({
      label: 'script',
      attrs: {
        async: true,
        ...attrs,
        src: 'https://apps.paidy.com/',
      },
    })
  }

  getChannelConfig({ billno, paymentCode }) {
    this.paymentCode = paymentCode
    return schttp({
      url: '/api/checkout/channelTradeConfig/get',
      params: { billno: billno, paymentCode: this.paymentCode }
    }).then(data => {
      const info = data?.info
      // console.log('getChannelConfig----', data)
      if (info) {
        this.channelTradeConfig = info || {}
        this.paidyConfig.api_key = info?.public_key || ''
        this.paidyConfig.token.type = info?.channelTokenType || ''
        if (!info?.billingName) {
          this._sendReport({
            apiPath: 'paidy/fail_to_get_name'
          })
        }
        if (!info?.public_key || !info?.channelTokenType) {
          this._sendReport({
            apiPath: 'paidy/fail_to_get_info',
            extraData: {
              errorData: JSON.stringify(data)
            }
          })
          return Promise.reject(info)
        }
        return this.paidyConfig
      }
      return Promise.reject(data)
    }, Promise.reject)
  }

  launch() {
    if (typeof Paidy === 'undefined') {
      console.log('paidy sdk 未初始化')
      this._sendReport({
        apiPath: 'paidy/fail_to_init_sdk',
        extraData: {
          msg: 'paidy sdk 未初始化'
        }
      })
      PaidyPay.eventCenter.emit('paidy-pay-error')
      return
    }
    const paidyHandler = Paidy.configure(this.paidyConfig)
    paidyHandler.launch({
      store_name: gbCommonInfo?.IS_RW ? 'ROMWE' : 'SHEIN',
      buyer: {
        name1: this.channelTradeConfig?.billingName || this.orderInfo.buyer_name || ''
      }
    })
  }

  setOrderInfo(order) {
    this.orderInfo = order
  }
}

export default PaidyPay
