<template>
  <div class="multi-return-coupon">
    <div class="multi-return-coupon__wrap">
      <div 
        class="wrap-title" 
        v-html="titleTxt"
      >
      </div>
      <div class="wrap-desc">
        {{ descTxt }}
      </div>
    </div>
    <div class="multi-return-coupon__bg">
      <img
        class="bg-img"
        :src="multiImgSrc"
      />
      <div
        v-if="totalValueSymbol"
        class="bg-value-wrap"
      >
        <AdaptText
          class="bg-value"
          :text="totalValueSymbol"
          :maxpx="80"
          :source="[10, 14]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { template } from '@shein/common-function'

import AdaptText from 'public/src/pages/components/AdaptText.vue'

export default {
  name: 'MultiReturnCoupon',
  components: { AdaptText },
  directives: { expose },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    couponPromotionInfo: {
      type: Object,
      default: () => {}
    },
    countTimerObj: {
      type: Object,
      default: () => {}
    },
    showCountDown: {
      type: Boolean,
      default: false
    },
    locals: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
    }
  },
  computed: {
    PUBLIC_CDN() {
      return this.locals?.PUBLIC_CDN ?? gbCommonInfo?.PUBLIC_CDN
    },
    GB_cssRight() {
      return this.locals?.GB_cssRight ?? gbCommonInfo?.GB_cssRight
    },
    // 获取标题文案
    titleTxt () {
      const { all_full_reduce_flag = 0, total_value = {}, coupon_num = 0 } = this.couponPromotionInfo || {}
      if (!this.showCountDown) {
        let langKey = all_full_reduce_flag === 1 ? 'SHEIN_KEY_PC_27525' : 'SHEIN_KEY_PC_27515'
        let value = all_full_reduce_flag === 1 ? total_value?.amountWithSymbol : coupon_num
        // 全是金额券，取优惠金额总和展示
        return `<i 
            class="sh_pc_sui_icon_couponbundle_18px"
          ></i>` + template(
          `<span>${value}</span>`,
          this.language?.[langKey]
        )
      }
      const { H, M, S } = this.countTimerObj || {}
      let spanTxt = txt => `<span class="fill">${txt}</span>`
      let langKey = all_full_reduce_flag === 1 ? 'SHEIN_KEY_PC_27513' : 'SHEIN_KEY_PC_27514'
      let value = all_full_reduce_flag === 1 ? total_value?.amountWithSymbol : coupon_num
      return template(
        `<span>${value}</span>`,
        spanTxt(H) + '<em>:</em>' + spanTxt(M) + '<em>:</em>' + spanTxt(S),
        this.language?.[langKey]
      )
    },
    descTxt () {
      const { coupon_num = 0 } = this.couponPromotionInfo || {}
      return template(
        coupon_num || 0,
        this.language?.SHEIN_KEY_PC_27671
      )
    },
    totalValueSymbol () {
      const { all_full_reduce_flag = 0, total_value = {} } = this.couponPromotionInfo || {}
      return all_full_reduce_flag === 1 ? total_value?.amountWithSymbol : ''
    },
    multiImgSrc () {
      const { all_full_reduce_flag = 0 } = this.couponPromotionInfo || {}
      if (all_full_reduce_flag == 1) {
        return this.GB_cssRight ? `${this.PUBLIC_CDN}/she_dist/images/coupon/sui_img_couponbudle_ar-21cb81bbf1.png` : `${this.PUBLIC_CDN}/she_dist/images/coupon/sui_img_couponbudle-bde0ea9ee3.png`
      }
      return this.GB_cssRight ? `${this.PUBLIC_CDN}/she_dist/images/coupon/sui_img_couponbudle_sale_ar-fecbb7dbf7.png` : `${this.PUBLIC_CDN}/she_dist/images/coupon/sui_img_couponbudle_sale-46a60933e9.png`
    }
  }
}
</script>

<style lang="less">
.multi-return-coupon {
  position: relative;
  z-index: @zindex-hack;
  .wrap-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 6px;
    color: @sui_color_main;
    span {
      color: @sui_color_discount
    }
    span.fill {
      color: @sui_color_white;
      padding: 2px;
      font-size: 12px;
      background-color: @sui_color_discount;
    }
    em {
      font-weight: 400;
      color: @sui_color_discount;
    }
  }
  .wrap-desc {
    color: @sui_color_gray_dark3;
    font-size: 12px;
    line-height: 14px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__wrap {
    position: relative;
    z-index: @zindex-hack + 1;
    padding-right: 64px;
  }
  &__bg {
    position: absolute;
    z-index: @zindex-hack;
    right: -16px;
    bottom: -16px;
    .bg-img {
      width: 100px;
      height: auto;
    }
    .bg-value-wrap {
      position: absolute;
      z-index: @zindex-hack;
      right: 0;
      top: 4px;
      width: 80px;
      height: 63px;
      .flexbox();
      justify-content: center;
      align-items: center;
    }
    .bg-value {
      color: #ED731B;
      transform: rotate(-10deg);
      text-shadow: 0 0.5px #FFF, 0.5px 0 #FFF, -0.5px 0 #FFF, 0 -0.5px #FFF;
      /* rtl:ignore */
      direction: ltr;
    }
  }
}

.sh_pc_sui_icon_couponbundle_18px {
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQuNTEyNSA0LjI4ODMzSDEyLjgwNkwxMS45OTk3IDEuMzAzNDdMMS4wNTUxIDQuMjg4MzNIMS4wMTI0NVY0LjI5OTk2TDAuOTk5NzU2IDQuMzAzNDJMMS4wMTI0NSA0LjM1ODQ0VjcuODM3ODJMMi4wMTI0NSA5LjI4ODNMMS4wMTI0NSAxMC43Mzg4VjEzLjc4ODNIMTQuNTEyNVY0LjI4ODMzWk0xMS4wODQ2IDIuOTAwNTdMNS45OTYwNiA0LjI4ODMzSDExLjQ1OTRMMTEuMDg0NiAyLjkwMDU3Wk0yLjMxMjQ1IDcuNDMzMTJMMy41OTE0NiA5LjI4ODNMMi4zMTI0NSAxMS4xNDM1VjEyLjQ4ODNIMTMuMjEyNVY1LjU4ODMzSDIuMzEyNDVWNy40MzMxMlpNNi45OTA0MiA3LjE3NzQ2QzYuOTkwNDIgNy42NjgzOCA2LjU5MjQ1IDguMDY2MzUgNi4xMDE1NCA4LjA2NjM1QzUuNjEwNjIgOC4wNjYzNSA1LjIxMjY1IDcuNjY4MzggNS4yMTI2NSA3LjE3NzQ2QzUuMjEyNjUgNi42ODY1NCA1LjYxMDYyIDYuMjg4NTcgNi4xMDE1NCA2LjI4ODU3QzYuNTkyNDUgNi4yODg1NyA2Ljk5MDQyIDYuNjg2NTQgNi45OTA0MiA3LjE3NzQ2Wk0xMC41NDYxIDEwLjczMzFDMTAuNTQ2MSAxMS4yMjQgMTAuMTQ4MSAxMS42MjIgOS42NTcyIDExLjYyMkM5LjE2NjI4IDExLjYyMiA4Ljc2ODMxIDExLjIyNCA4Ljc2ODMxIDEwLjczMzFDOC43NjgzMSAxMC4yNDIyIDkuMTY2MjggOS44NDQyNCA5LjY1NzIgOS44NDQyNEMxMC4xNDgxIDkuODQ0MjQgMTAuNTQ2MSAxMC4yNDIyIDEwLjU0NjEgMTAuNzMzMVpNOC45MjcxIDYuMjg4NTdMNS4yMTI2NSAxMS42MjIxSDYuODMxNThMMTAuNTQ2IDYuMjg4NTdIOC45MjcxWiIgZmlsbD0iYmxhY2siPgo8L3BhdGg+Cjwvc3ZnPg==) no-repeat;
  background-size: 100%;
  vertical-align: text-bottom;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
</style>
