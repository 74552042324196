<template>
  <div
    v-if="cartsInfo && cartsInfo.length"
    v-expose="{id: '1-11-1-203'}"
    class="check-bag check-bag__effiency"
    :class="{ 
      'hide-bag-bottom': hideBagBottom,
    }"
  >
    <div
      class="check-bag-box"
    >
      <div 
        class="platform-title-checkout"
        :class="[{'bg-effiency': isSiteMallList && !GB_cssRight, 'bg-ar-effiency': isSiteMallList && GB_cssRight}]"
      >
        <strong v-if="isSiteMallList">{{
          cartsMallInfo && cartsMallInfo.mall_name
        }}</strong>
        <strong v-else>{{ language.SHEIN_KEY_PC_25384 }}</strong>
        <span
          class="carts-more"
          @click="openModal"
        >
          {{ cartProductTotal(cartsInfo) }}
          <sui_icon_more_right_14px
            class="carts-more__icon"
            size="14px"
            color="#222222"
          />
        </span>
      </div>

      <!-- 仅下单页展示，二次下单页不展示 -->
      <ShoppingBagTips 
        v-if="checkoutScene === 'Checkout'"
        :carts-info="cartsInfo"
        :mall-code="mallCode"
        :is-last-goods="isLastGoods" 
      />
      <CheckoutCartContent 
        :carts-info="cartsInfo"
        :quick-ship-carts="quickShipCarts"
        :no-quick-ship-carts="noQuickShipCarts"
        :chemicals-ids="chemicalsIds"
        :tag-ids-map="tagIdsMap"
        :show-qs-freight="showQsFreight"
        :desc-quick-ship-time="descQuickShipTime"
        :freight-checked="freightChecked"
        :checkout-scene="checkoutScene"
        :qs-freight-info="qsFreightInfo"
        :can-edit-shoppingbag="canEditShoppingbag"
        :is-last-goods="isLastGoods"
        :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
        :discount-logo-by-abt="discountLogoByAbt"
        :under-price-abt-value="underPriceAbtValue"
        :is-suggested="isSuggested"
        :large-ship-extends-info="largeShipExtendsInfo"
        :is-site-mall-list="isSiteMallList"
        :shipping-method="shippingMethod"
        @show-quick-pop="showQuickPop"
        @toggle-qs-freight="toggleQsFreight"
        @open-modal="openModal"
      />
    </div>

    <ClientOnly>
      <ShoppingBagList
        ref="shoppingBagModal"
        :cart-modal-list="cartsInfo"
        :carts-mall-info="cartsMallInfo"
        :chemicals-ids="chemicalsIds"
        :tag-ids-map="tagIdsMap"
        :desc-quick-ship-time="descQuickShipTime"
        :can-edit-shoppingbag="canEditShoppingbag"
        :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
        :discount-logo-by-abt="discountLogoByAbt"
        :is-suggested="isSuggested"
        :under-price-abt-value="underPriceAbtValue"
        :checkout-scene="checkoutScene"
        :large-ship-extends-info="largeShipExtendsInfo"
        :is-site-mall-list="isSiteMallList"
      />
    </ClientOnly>
  </div>
</template>

<script>
// 该文件使用范围 下单页，二次下单页
import CheckoutCartContent from './CheckoutCartContentEffiency'
import ShoppingBagList from './EffiencyBagList.vue'
import ShoppingBagTips from './shopping_bag/ShoppingBagTips.vue'

import { template as _commonTemplate } from '@shein/common-function'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'

import { sortCartsByNum, sortTogetherBuyCart, sortLargeShipCarts } from 'public/src/pages/checkout/config/tools.js'
import { filterPromitionGoods } from 'public/src/pages/checkout/utils.js'
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'
import { fetchChemicalsIdsApi } from 'public/src/services/api/pay/checkout.js'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'CheckoutCart',
  directives: { tap, expose },
  components: {
    ShoppingBagList,
    CheckoutCartContent,
    sui_icon_more_right_14px,
    ShoppingBagTips
  },
  props: {
    cartsInfo: [Object, Array],
    cartsMallInfo: {
      type: Object,
      default: () => {},
    },
    quickShipInfo: {
      type: Object,
      default: () => {}
    },
    mallCode: {
      type: [String, Number],
      default: '',
    },
  },
  data: function () {
    return {
      chemicalsIds: [],
      tagIdsMap: {},
      quickShipCarts: [], 
      noQuickShipCarts: [],
      isSendedEvent: false,
    }
  },
  computed: {
    ...mapState([
      'defaultShipping', 
      'caculateData', 
      'togetherBuy', 
      'checkout',
      'language',
      'locals',
      'checkoutScene',
      'qsMallList'
    ]),
    ...mapGetters([
      'shoppingBagPosition', 
      'showQuickShipByAbt',
      'canEditShoppingbag',
      'shoppingBagCrossedPriceByAbt',
      'discountLogoByAbt',
      'underPriceAbtValue',
      'quickShipCheckoutStatus',
      'isSuggested',
      'switchOnEvoluSHEIN',
      'EVOLU_SHEIN_ID',
      'largeShipExtendsInfo',
    ]),
    GB_cssRight() {
      return this.locals?.GB_cssRight ?? gbCommonInfo?.GB_cssRight
    },
    caculateInfo() {
      if (this.checkoutScene != 'Checkout') {
        return this.checkout?.order || {}
      } else {
        return this.checkout.mall_caculate_info
      }
    },
    Need_Qs_Freight(){
      return [ 108 ].includes(+this.checkout?.default_address?.countryId)
    },
    p65FlagInfo() {
      return this.caculateInfo?.p65_flag || []
    },
    // 站点mall 多mall站样式按多mall来.
    isSiteMallList() {
      return this.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
    },
    hasAddress() {
      if (this.checkoutScene != 'Checkout') {
        return true
      } else {
        return !!this.checkout?.default_address?.addressId
      }
    },
    descQuickShipTime() {
      let descQuickShipTime = ''
      if (this.quickShipCarts.length) descQuickShipTime = this.quickShipDescribe(this.quickShipInfo)
      return descQuickShipTime
    },
    shippingMethod() {
      if (this.checkoutScene != 'Checkout') {
        const { mall_list = [] } = this.checkout?.results?.mallShippingOrderMethod || {}
        const filterMethod = mall_list.find((item) => item.mall_code == this.mallCode) || {}
        return filterMethod?.shipping_methods?.[0] || {}
      } else {
        const list = this.defaultShipping?.filter(item => item.mall_code == this.mallCode) || []
        return list?.[0]?.shipping_method
      }
    },
    qsFreightInfo() {
      const { qs_freight_name = null, qs_freight_price = null, qs_freight_status = null, qs_freight_tips = null } = this.shippingMethod || {}
      return { qs_freight_name, qs_freight_price, qs_freight_status, qs_freight_tips }
    },
    freightChecked () {
      if (this.checkoutScene != 'Checkout') { // 二次下单页状态禁止修改，仅有选中状态才会展示
        return true
      } else {
        const { mall_params = [] } = this.caculateData || {}
        const params = mall_params?.find(mall => mall.mall_code == this.mallCode) || {}
        // 0=不处理，1=按照qs处理，2=a按照跨境处理(只有入参为1时选中)
        const { switch_qs_flag = 0 } = params
        if (this.showQsFreight) {
          this.assignState({
            qsFreightSelected: switch_qs_flag == 1
          })
        }
        return switch_qs_flag == 1
      }
    },
    showQsFreight() {
      if (this.checkoutScene != 'Checkout') {
        return +this.checkout?.order?.quick_shipping_freight?.quick_shipping_freight_price?.amount > 0
      } else {
        return this.qsFreightInfo.qs_freight_status == 1 && this.qsMakeup != 0
      }
    },
    qsMakeup() {
      if (this.checkoutScene != 'Checkout') {
        return 0
      } else {
        return this.checkout.checkoutBFFAbtInfo?.quickshipFeeSwitch?.param?.qsMakeup || 0
      }
    },
    hideBagBottom() {
      return this.shoppingBagPosition && this.hasAddress
    },
    isLastGoods() {
      // 是否是最后一个商品（当前结算车内最后一个，并非当前mall），最后一个商品去除赠品 ,qs拆车数据算同个商品
      const otherCarts = this.checkout.results?.carts?.carts?.filter(item => item.mall_code != this.mallCode)
      const otherCartsWithoutPromition = filterPromitionGoods(otherCarts)
      const carts = filterPromitionGoods(this.cartsInfo)

      return otherCartsWithoutPromition.length == 0 && carts.length == 1
    },
  },
  watch: {
    cartsInfo: {
      handler(v, oldValue) {
        if(!oldValue) { // 初始化
          const { quickShipCarts, noQuickShipCarts } = this.initCartsList()
          this.quickShipCarts = quickShipCarts
          this.noQuickShipCarts = noQuickShipCarts
        } else {
          const { quickShipCarts, noQuickShipCarts } = this.initCartsList()
          this.quickShipCarts = this.handleCartsList(quickShipCarts, this.quickShipCarts)
          this.noQuickShipCarts = this.handleCartsList(noQuickShipCarts, this.noQuickShipCarts)

          this.handleTogetherBuyData()
        }
      },
      immediate: true,
      deep: true
    },
    p65FlagInfo(newVal, oldVal) {
      if (newVal?.length != oldVal?.length) {
        this.chemicalsIdsEvt()
      }
    },
    descQuickShipTime: {
      handler(v) {
        if (v) {
          if(typeof window == 'undefined') return

          daEventCenter.triggerNotice({
            daId: '1-11-1-89',
            extraData: {
              quickship_tp: !this.quickShipCarts.length ? '0' : (this.quickShipCarts.length == this.cartsInfo.length ? '1' : '2')
            }
          })
        }
      },
      immediate: true
    },
    'qsFreightInfo.qs_freight_tips': { // 该值只有下单页使用，二次下单页订单接口返回
      handler(n) {
        if (n && this.checkoutScene == 'Checkout') {
          this.assignState({
            qsFreightTips: n
          })
        }
      },
      immediate: true
    },
    'shippingMethod.transport_type': {
      handler(n, o) {
        if (n && n != o && this.showQsFreight) {
          if(typeof window == 'undefined') return

          daEventCenter.triggerNotice({
            daId: '1-11-1-104',
            extraData: {
              default_qs_freight_status: this.freightChecked ? 1 : 0,
              mall_code: this.mallCode,
              shipping_method: this.shippingMethod?.transport_type || '',
            }
          })
        }
      },
      immediate: true
    },
    'qsMallList.last_quickship_tag': {
      handler(){
        // 当前国家是日本，不区分qs、非qs商品
        if(this.Need_Qs_Freight){
          if(this.qsMallList.last_quickship_tag){
            this.quickShipCarts = [...this.quickShipCarts, ...this.noQuickShipCarts]
            this.noQuickShipCarts = []
          }
        }
      }
    }
  },
  mounted() {
    this.chemicalsIdsEvt()
  },
  methods: {
    ...mapMutations(['assignState', 'changeParamsStatus']),
    initCartsList() {
      let quickShipCarts = []
      let noQuickShipCarts = []
      // 购物袋外层按商品数量排序
      const sortedList = sortCartsByNum( JSON.parse(JSON.stringify(this.cartsInfo)))
      const fullyNewCarts = sortLargeShipCarts({ isSiteMallList: this.isSiteMallList, largeShipExtendsInfo: this.largeShipExtendsInfo, carts: sortedList })
      if(this.showQuickShipByAbt) {
        const list = this.handleQsCarts(fullyNewCarts)
        quickShipCarts = list?.filter(item => item.quick_ship && item?.over_quick_ship != 1) || []
        noQuickShipCarts = list?.filter(item => !item.quick_ship || item?.over_quick_ship == 1) || []
      } else {
        quickShipCarts = []
        noQuickShipCarts = fullyNewCarts
      }

      return {
        quickShipCarts,
        noQuickShipCarts
      }
    },
    handleCartsList(newList, list) {
      let results = []
      const delIds = []
      list.forEach(item => {
        const target = newList.find(f => f.id === item.id)
        if (!target) delIds.push(item.id)
      })
      // del
      results = list.filter(f => !delIds.includes(f.id))
      
      newList.forEach(item => {
        const target = results.find(f => f.id === item.id)
        if (!target) {
          // add
          results.unshift(item)
        } else {
          // update
          const findIndex = results.findIndex(f => f.id === item.id)
          results[findIndex] = item
        }
      })

      return results
    },
    handleTogetherBuyData() {
      if(this.togetherBuy?.addCartProducs) {
        this.quickShipCarts = sortTogetherBuyCart(this.quickShipCarts, this.togetherBuy.addCartProducs)
        this.noQuickShipCarts = sortTogetherBuyCart(this.noQuickShipCarts, this.togetherBuy.addCartProducs)
      }
    },
    toggleQsFreight() {
      if (this.checkoutScene != 'Checkout') return

      const { mall_params = [] } = this.caculateData || {}
      const params = mall_params.map(mall => {
        if (mall.mall_code != this.mallCode) return mall
        // 选中入参传1，未选中传2
        return Object.assign({}, mall, { switch_qs_flag: !this.freightChecked ? 1 : 2 })
      })
      
      this.assignState({
        isClickedQsFreight: true
      })
      this.changeParamsStatus({ type: 'caculateData', params: { mall_params: params } })

      window.checkoutEventCenter.emit('update-total', {
        opt: {
          ...this.caculateData,
        },
      })

      daEventCenter.triggerNotice({
        daId: '1-11-1-105',
        extraData: {
          qs_freight_status: this.freightChecked ? 1 : 0,
          mall_code: this.mallCode,
          shipping_method: this.shippingMethod?.transport_type || '',
        }
      })
    },
    // 根据qs_stock与quantity拆分qs数据（qs库存与购买数量）
    handleQsCarts(originCarts) {
      // 超出库存范围商品数据
      let overQsCarts = []
      const isCheckout = this.checkoutScene == 'Checkout' // 是否是下单页
      const list = originCarts.map(item => {
        // 下单页字段 real_quick_ship qs_stock
        // 二次下单页字段 quick_ship quickShipCount
        const quantity = item?.quantity || 0
        let qs_stock = item?.product?.qs_stock || 0
        const quick_ship_count = item?.quickShipCount || 0
        qs_stock = isCheckout ? qs_stock : quick_ship_count

        if ((item?.real_quick_ship != 2 && isCheckout) || (item?.quick_ship != 2 && !isCheckout)) return item

        let overQuantity = +quantity - +qs_stock
        // 手动增加 over_quick_ship 标识处理超出库存数据
        let overItem = Object.assign({}, item, { quantity: overQuantity, over_quick_ship: 1 })
        overQsCarts.push(overItem)

        return Object.assign({}, item, { quantity: qs_stock })
      })

      // 数量大于0才保留
      const mergeList = list.concat(overQsCarts)?.filter(item => +item.quantity > 0) || []
      const sortedList = sortCartsByNum(mergeList)

      return sortLargeShipCarts({ isSiteMallList: this.isSiteMallList, largeShipExtendsInfo: this.largeShipExtendsInfo, carts: sortedList })
    },
    // quickship文案描述
    quickShipDescribe(item) {
      if(!item.desc_quick_ship_time) return ''
      let _html = item.desc_quick_ship_time || ''

      if( _html ){
        _html = _html.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')
        this.quickShipCheckoutStatus?.quickShipLabelText && (_html = _html.replace(/{QSname}/g, `${this.quickShipCheckoutStatus.quickShipLabelText}`)
          .replace(/\[time\]/g, `${item.quick_ship_time}`))
      }
      return _html
    },
    showQuickPop() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-90',
        extraData: {
          quickship_tp: !this.quickShipCarts.length ? '0' : (this.quickShipCarts.length == this.cartsInfo.length ? '1' : '2')
        }
      })
      this.assignState({
        isShowQuickPop: true
      })
    },
    async chemicalsIdsEvt() {
      let { p65_flag = [] } = this.caculateInfo
      const p65FlagInfo = p65_flag?.filter((item) => item.flag == 1)
      // 商品sku集合
      const skuArr = p65FlagInfo?.map((item) => item.goods_sn)
      // 商品tag集合
      let matchTagIds = []
      p65FlagInfo?.forEach((item) => {
        if (item?.tags?.length) {
          let tags = item?.tags?.sort((prev, next) => prev - next)
          matchTagIds.push(+tags?.[tags.length - 1])
        }
      })
      let tagsMap = {}
      if (matchTagIds?.length) {
        // 批量获取提示文案
        let dataArr = []
        if (matchTagIds.length <= 20) {
          let res = await fetchChemicalsIdsApi({
            matchTagIds: matchTagIds
          })
          dataArr = res?.data || []
        } else {
          let tagsIdArr = []
          for (let i = 0; i < matchTagIds.length; i += 20) {
            tagsIdArr.push(matchTagIds.slice(i, i + 20))
          }
          let promiseList = tagsIdArr.map((tagsList) =>{
            return fetchChemicalsIdsApi({
              matchTagIds: JSON.stringify(tagsList)
            })
          })
          let res = await Promise.all(promiseList)
          res?.length &&
            res.forEach((list) => (dataArr = dataArr.concat(list?.data || [])))
        }
        dataArr?.forEach((item) => {
          let { tagId = '', content = [] } = item
          if (matchTagIds.includes(+tagId)) {
            let goodsSnArr = p65_flag?.filter(
              (flagObj) =>
                flagObj?.tags.includes(`${tagId}`) ||
                flagObj?.tags.includes(tagId)
            )
            goodsSnArr?.forEach((goodsSnObj) => {
              if (goodsSnObj?.goods_sn) {
                tagsMap[goodsSnObj?.goods_sn] =
                  content?.[0]?.content?.props?.items?.[0]?.content || ''
              }
            })
          }
        })
      }
      this.tagIdsMap = tagsMap
      this.chemicalsIds = skuArr || []
      this.assignState({
        tagIdsMap: tagsMap,
        chemicalsIds: skuArr || []
      })
    },
    cartProductTotal: function (carts) {
      if (!carts.length) return ''
      let total = carts.reduce((total, item) => {
        total = total + Number(item.quantity)
        return total
      }, 0)
      return total > 1 ? this.template(total, this.language.SHEIN_KEY_PC_24555) : this.language.SHEIN_KEY_PC_24557
    },
    clickShoppingBagBi() {
      const storeCodeList = this.cartsInfo?.map(item => item.store_code) || []
      const store_code = storeCodeList.join(',') || ''
      const selfCarts = this.cartsInfo?.filter(item => item.business_model == 0) || []
      const is_self_num = !selfCarts.length ? '0' : (selfCarts.length == this.cartsInfo.length ? '1' : '0,1')
      daEventCenter.triggerNotice({
        daId: '1-11-1-88',
        extraData: {
          is_self_num,
          store_code,
          click_from: 'button',
        }
      })
    },
    trackEvent() {
      // abt控制evoluSHEIN打开，且有evoluSHEIN标签的商品才上报事件
      if(this.switchOnEvoluSHEIN) {
        let tag_info = '', isSendEvent = false
        this.cartsInfo && Array.isArray(this.cartsInfo) && this.cartsInfo.forEach(item => {
          if(item?.rules?.includes(this.EVOLU_SHEIN_ID)) {
            isSendEvent = true
            tag_info === '' ? tag_info += `${item?.product?.sku_code}${'`'}show_evolushein` : tag_info += `,${item?.product?.sku_code}${'`'}show_evolushein`
          }
        })
        if(!this.isSendedEvent && isSendEvent) {
          daEventCenter.triggerNotice({
            daId: '1-11-1-222',
            extraData: {
              postion: 'popup',
              tag_info
            }
          })
          this.isSendedEvent = true
        }
      }
    },
    openModal() {
      this.$refs.shoppingBagModal.init()
      this.clickShoppingBagBi()
      this.trackEvent()
    },
    template: _commonTemplate,
  },
}
</script>

<style lang="less">
.check-bag-box {
  border: 0;
  .platform-title-checkout {
    display: flex;
		justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0;
    margin-bottom: 12px;
    border-bottom: 0;

    strong {
      font-size: 20px;
      font-family: 'Arial Black';

      & when (@IS_RW) {
        font-family: Adieu;
      }
    }

    .carts-more {
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      &__icon {
        margin-left: 2px;
      }
    }
  }
}

.check-bag__effiency {
  .bg-effiency {
    padding: 8px 16px;
    background: url(/she_dist/images/checkout/sui_img_mallbg_checkout@2x-79fcffa40a.png);
    background-size: 100% 100%;
  }
  .bg-ar-effiency {
    padding: 8px 16px;
    background: url(/she_dist/images/checkout/sui_img_mallbg_checkout_ar@2x-1a97bcbc9d.png);
    background-size: 100% 100%;
  }

  background: #fff;
  &:not(.hide-bag-bottom) {
    margin-bottom: 10px;
  }

  .check-bag-box  {
    margin-top: 0;
  }
}

.hide-bag-bottom {
  margin-bottom: 0;
}

.shopping-bag__hint {
  padding: 10px 8px;
  margin: 0 16px 16px;
  min-height: unset;
}

.shopping-bag-dynamic-class {
  .checkout-cart__item-effiency:first-child {
    // width: 0;
    opacity: 0;
  }
}
.is-single {
  margin-top: 10px;
  padding-bottom: 16px;
}
</style>
