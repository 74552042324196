
<template>
  <div class="price-item-details">
    <li 
      v-for="(item, index) in details"
      :key="index"
      class="price-item-details__item" 
    >
      <span>
        {{ item.title }}
        <s-popover
          v-if="item.tips"
          class="price-item-details__popover"
          :hide-close-icon="true"
          placemen="bottom"
          trigger="hover"
          :content="item.tips"
        >
          <template #reference>
            <sui_icon_doubt_16px_1
              class="popover-item-icon"
              size="14px"
              color="#767676"
            />
          </template>
        </s-popover>
      </span>
      <span>{{ item.price }}</span>
    </li>
  </div>
</template>

<script>
import { sui_icon_doubt_16px_1 } from '@shein-aidc/icon-vue3'
export default {
  components: {
    sui_icon_doubt_16px_1,
  },
  props: {
    details: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
.price-item-details {
  color: @sui_color_gray_dark3;
  font-size: 12px;
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 4px 0;
    &:last-child {
      margin-bottom: 8px;
    }
  }
  &__tips-icon {
    color: @sui_color_gray_light1;
    vertical-align: middle;
  }
  &__popover {
    color: @sui_color_gray_light1;
    vertical-align: middle;
    .popover-item-icon {
      cursor: pointer
    }
  }
}
</style>
